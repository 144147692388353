<template>
  <div class="container">
    <div class="top">
      <div class="top_title">
        <div class="top_title_small"> {{info.title_1}} </div>
        <div class="top_title_big"> {{info.title_2}} </div>
      </div>
      <div class="top_line"></div>
      <div class="top_hint">
        <div class="top_hint_small"> {{info.hint_1}} </div>
        <div class="top_hint_small"> {{info.hint_2}} </div>
      </div>
    </div>
    <div class="mid">
      <el-form class="top_form" :inline="true" :model="form" label-width="800px">
        <div class="top_form_t">
          <el-form-item class="top_form_item_1">
            <div class="item_top">一级行政区划（省/自治区/地级市）</div>
            <div class="item_bottom">
              <el-select v-model="form.provincial">
                <el-option
                    v-for="item in provincialList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="top_form_item_1">
            <div class="item_top">二级行政区划（地级市/自治州/盟，等）</div>
            <div class="item_bottom">
              <el-select v-model="form.provincial">
                <el-option
                    v-for="item in provincialList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
        </div>
        <el-form-item class="top_form_item_1">
          <div class="item_top">姓名</div>
          <div class="item_bottom">
            <el-input v-model="form.name" placeholder="请填写您的姓名"></el-input>
          </div>
        </el-form-item>
        <el-form-item class="top_form_item_1">
          <div class="item_top">电话</div>
          <div class="item_bottom">
            <el-input v-model="form.tel" placeholder="请填写您的电话号码"></el-input>
          </div>
        </el-form-item>
        <el-form-item class="top_form_item_1">
          <div class="item_top">邮箱</div>
          <div class="item_bottom">
            <el-input v-model="form.email" placeholder="请填写您的收件邮箱"></el-input>
          </div>
        </el-form-item>
        <el-form-item class="top_form_item_1">
          <div class="item_top">公司</div>
          <div class="item_bottom">
            <el-input class="item_bottom_input" v-model="form.company" placeholder="请填写您的公司名称"></el-input>
          </div>
        </el-form-item>
        <el-form-item class="top_form_item_1">
          <div class="item_top">申请说明</div>
          <div class="item_bottom">
            <el-input class="item_bottom_input" type="textarea" v-model="form.apply" placeholder="请填写您在加盟当地的资源及优势"></el-input>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="bottom">
      <div class="btn">提交</div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "partner",
    data(){
      return {
        info: {
          title_1: '城市合伙人',
          title_2: '招募计划',
          hint_1: '合作共享',
          hint_2: '开放共赢',
        },
        form: {
          provincial: '',
          city: '',
        },
        provincialList: [
          { label: '北京', value: '北京' }
        ],
        cityList: [
          { label: '海口', value: '海口' }
        ],
      }
    },
    created(){
    }
  };
</script>

<style lang="stylus" scoped>
  .container{
    display flex
    flex-flow column
    background #F5F5F7
    .top{
      display flex
      flex-flow column
      align-items center
      justify-content center
      width 100%
      height 800px
      background url("https://nontax-cdn.yuluoo.com/yuluooOfficialWeb/compony_banner.png") no-repeat
      background-size 100% 100%
      .top_title{
        display flex
        font-size 96px
        color #343A40
        .top_title_small{
        }
        .top_title_big{
          font-weight 900
        }
      }
      .top_line{
        width 250px
        height 20px
        background #007BFF
        margin-top 39px
      }
      .top_hint{
        display flex
        font-size 40px
        color #343A40
        margin 32px 0 0 12px
        .top_hint_small{
        }
        .top_hint_big{
        }
      }
      .top_btn{
        width 288px
        height 75px
        line-height 75px
        border-radius 8px
        background #007BFF
        color #FFF
        font-size 32px
        text-align center
        margin-top 104px
      }
    }
    .mid{
      display flex
      flex-flow column
      align-items center
      .top_form{
        display flex
        flex-flow column
        width 800px
        .top_form_t{
          display flex
          .top_form_item_1{
            width 100%
            .el-form-item__content{
              width:100%
            }
            .item_top{
              display flex
            }
            .item_bottom{
              display flex
              .el_input{
                width 100%
              }
            }
          }

        }
      }
    }
    .bottom{
      display flex
      justify-content center
      align-items center
      .btn{
        width 80px
        height 51px
        line-height 51px
        text-align center
        background #007BFF
        border-radius 4px
        font-size 20px
        color #fff
      }
    }
  }
  .el-input{
    width: 800px;
  }
  .el-textarea{
    width: 800px;
    height: 92px;
  }
</style>
